import React, { useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import img from './solana.png';
import imgTaskLisk from './task-list.png';
import imgWelcome from './welcome-back.png';
import imgJoin from './join.png';
import imgSubscriber from './subscriber.png';
import imgAddUser from './add-user.png';
import imgVisits from './visits.png';
import imgBooster from './booster.png';
import imgSolanaBlack from './solana_black.png';
import imgSolanaGold from './solana_gold.png';
const Missions = () => {
  return (
    <div className='container'>
      
      <ToastContainer />
      <div className='text-header-mission'>
      <img src={imgTaskLisk} alt="Description" className="image-missions img-pulse" />
      </div>
      <div className='text-header-mission'>
      <p>6 tasks available</p>
      </div>
      <div className='block-text-center'>
      <p>We'll reward you immediately with points after each task completion</p>
      </div>

     <div className='block-wallet-list'>
     <img src={imgWelcome} alt="Description" className="float-left image invert-image" />
     
      <div className='float-left block-send-position'>
        <div className='text-home-1 text-bold'>Welcome bonus</div>
        <div className='mission-img'>
        <img src={imgSolanaGold} alt="Description" className="m-10 float-left image-mis" />
        <div className='text-home-2 float-left'>+0.0001 SOL</div>
        </div>
      </div>
      <p className='button bg-white'>Start</p>
     </div>
     <div className='block-wallet-list'>
     <img src={imgJoin} alt="Description" className="float-left image invert-image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1 text-bold'>Join Solanius community</div>
        <div className='mission-img'>
        <img src={imgSolanaGold} alt="Description" className="m-10 float-left image-mis" />
        <div className='text-home-2 float-left'>+0.0001 SOL</div>
        </div>
      </div>
      <p className='button bg-white'>Start</p>
     </div>
     <div className='block-wallet-list'>
     <img src={imgSubscriber} alt="Description" className="float-left image invert-image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1 text-bold'>Subscribe to Solanius announcements</div>
        <div className='mission-img'>
        <img src={imgSolanaGold} alt="Description" className="m-10 float-left image-mis" />
        <div className='text-home-2 float-left'>+0.0001 SOL</div>
        </div>
      </div>
      <p className='button bg-white'>Start</p>
     </div>
     <div className='block-wallet-list'>
     <img src={imgSubscriber} alt="Description" className="float-left image invert-image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1 text-bold'>Subscribe to Solana announcements</div>
        <div className='mission-img'>
        <img src={imgSolanaGold} alt="Description" className="m-10 float-left image-mis" />
        <div className='text-home-2 float-left'>+0.0001 SOL</div>
        </div>
      </div>
      <p className='button bg-white'>Start</p>
     </div>
     <div className='block-wallet-list'>
     <img src={imgAddUser} alt="Description" className="float-left image invert-image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1 text-bold'>Invite your first friend</div>
        <div className='mission-img'>
        <img src={imgSolanaGold} alt="Description" className="m-10 float-left image-mis" />
        <div className='text-home-2 float-left'>+0.0001 SOL</div>
        </div>
      </div>
      <p className='button bg-white'>Start</p>
     </div>
     <div className='block-wallet-list'>
     <img src={imgVisits} alt="Description" className="float-left image invert-image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1 text-bold'>Visit Solana website</div>
        <div className='mission-img'>
        <img src={imgSolanaGold} alt="Description" className="m-10 float-left image-mis" />
        <div className='text-home-2 float-left'>+0.0001 SOL</div>
        </div>
      </div>
      <p className='button bg-white'>Start</p>
     </div>
     <div className='block-wallet-list'>
     <img src={imgBooster} alt="Description" className="float-left image invert-image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1 text-bold'>Rent your first power booster</div>
        <div className='mission-img'>
        <img src={imgSolanaGold} alt="Description" className="m-10 float-left image-mis" />
        <div className='text-home-2 float-left'>+0.0001 SOL</div>
        </div>
        
      </div>
      <p className='button bg-white'>Start</p>
     </div>
    </div>
  );
};

export default Missions;
import React, { useState, useEffect } from 'react';
import '../App.css';
const BoostTimer = () => {
    const [timeLeft, setTimeLeft] = useState(8 * 60 * 60); // 8 часов в секундах

    useEffect(() => {
      const interval = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 1) {
            clearInterval(interval);
            alert('8 часов прошло!');
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);
  
    const formatTime = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;
      return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

  return (
    <div>
      <div className='boost-timer'>
        <span>{formatTime(timeLeft)}</span>
      </div>
    </div>
  );
};

export default BoostTimer;

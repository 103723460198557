import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BottomMenu from './BottomMenu';
import Home from './Home';
import Wallet from './Wallet';
import Friends from './Friends';
import Missions from './Missions';


const App = () => {
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.expand();
    }
}, []);
  return (
    <Router>
      <main>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/friends" element={<Friends />} />
          <Route path="/Missions" element={<Missions />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <BottomMenu />
      </main>
    </Router>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import BoostTimer from './components/BoostTimer';
import Modal from './components/Modal';
import './App.css';
import img from './solana.png';
import imgBoost from './boost.png';
import imgSolanaGold from './solana_gold.png';
import imgWithdrawing from './withdrawing.png';
import imgClaim from './claim.png';
import imgBoostModal from './boost_modal.png';
const Home = () => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 2000);
  };
  const [showBoostModal, setShowBoostModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showClaimModal, setShowClaimModal] = useState(false);

  const handleOpenBoostModal = () => setShowBoostModal(true);
  const handleCloseBoostModal = () => setShowBoostModal(false);

  const handleOpenSendModal = () => setShowSendModal(true);
  const handleCloseSendModal = () => setShowSendModal(false);

  const handleOpenClaimModal = () => setShowClaimModal(true);
  const handleCloseClaimModal = () => setShowClaimModal(false);

  return (
    <div className='container'>

     <div className='block-send'>
     <img src={imgSolanaGold} alt="Description" className="float-left image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1'>Solana Balance</div>
        <div className='text-home-2'>0.0032 <span className='text-bold'>SOL</span></div>
      </div>
      <button onClick={handleOpenSendModal} className='float-right button'>Send</button>
     </div>

     <div className='block-image' onClick={handleClick}>
     <img src={img} className={`solana-image ${clicked ? 'clicked' : ''}`} alt="Placeholder" />
      
     </div>
     <div className='block-cb'>
     {MiningSimulation()}
     </div>
     <div className='block-cb'>
     <p className='p-2'>1.0 GH/s</p>
     </div>
     <div className='block-two-button'>
     <button onClick={handleOpenClaimModal} className='button'>Claim</button>
     <button onClick={handleOpenBoostModal} className='button'>Boost</button>
     </div>
    {/*  <div className='block-image'>
     <img src={imgBoost} className={`boost-image ${clicked ? 'clicked' : ''}`} alt="Placeholder" />
     </div> */}
     <div className='block-boost-timer'>
     <BoostTimer />
     </div>
     <Modal show={showBoostModal} handleClose={handleCloseBoostModal}>
     <div className='block-image'>
      <img src={imgBoostModal} className='boost-image' alt="Placeholder" />
      </div>
        <h2>Mining Power</h2>
        <p>Here you can rent mining power for 30 days.</p>
        <p>The investment profitability is 5.18% per day and 155% for 30
				days</p>
        <div>
          <div className='boost-text-top'>
          ⚡ 10.0 GH/s
          </div>

          <div className='boost-text'>
          Total Profit: ~1.55 SOL
          </div>
          <div className='boost-text'>
          Daily Profit: ~0.16 SOL
          </div>
        </div>
        <input type='number' placeholder='Amount' min={1.55} max={100}></input>
        <div className='boost-text'>Minimum amount: 0.003 SOL</div>
        <button className='modal-button'>Add</button>
      </Modal>
      <Modal show={showSendModal} handleClose={handleCloseSendModal}>
      <div className='block-image'>
      <img src={imgWithdrawing} className='boost-image' alt="Placeholder" />
      </div>
        <h2>Enter your personal SOL address</h2>
        <p>This amount will be sent to the SOLANA compatible wallet address</p>
        <p>Enter your personal SOL address</p>
        <input type='text' placeholder='Your SOL (SOLANA) Address'></input>
        <input type='number' placeholder='Amount'></input>
        <button className='modal-button'>Send</button>
      </Modal>
      <Modal show={showClaimModal} handleClose={handleCloseClaimModal}>
      <div className='block-image'>
      <img src={imgClaim} className='boost-image' alt="Placeholder" />
      </div>
        <h2>Claim SOL to Wallet Balance</h2>
        <p>Once claimed, the mined SOL will be deducted from your mining balance and will be credited to your wallet balance.</p>
        <p>Minimum claim amount is 0,01 SOL.</p>
        <button className='modal-button'>Claim</button>
      </Modal>
    </div>
);
};

function MiningSimulation() {
  const fpms = 10;
  const rpms = 6.0E-8;
  const [mined, setMined] = useState(0.16004);

  useEffect(() => {
      const intervalId = setInterval(() => {
          setMined(prevMined => prevMined + (rpms * fpms));
      }, fpms);

      return () => clearInterval(intervalId);
  }, []);

  return (
      <div>
          <p className='p-1'>{mined.toFixed(6)} SOL</p>
      </div>
  );
}
export default Home;
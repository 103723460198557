import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Modal from './components/Modal';
import './App.css';
import img from './solana.png';
import imgWithdrawing from './withdrawing.png';
import imgBoostModal from './boost_modal.png';
import imgSolanaGold from './solana_gold.png';
const Wallet = () => {
  const [showBoostModal, setShowBoostModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);

  const handleOpenBoostModal = () => setShowBoostModal(true);
  const handleCloseBoostModal = () => setShowBoostModal(false);

  const handleOpenSendModal = () => setShowSendModal(true);
  const handleCloseSendModal = () => setShowSendModal(false);
  return (
    <div className='container'>

     <div className='block-send'>
     <img src={imgSolanaGold} alt="Description" className="float-left image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1'>Solana Balance</div>
        <div className='text-home-2'>0.0032 <span className='text-bold'>SOL</span></div>
      </div>
      <button onClick={handleOpenSendModal} className='float-right button'>Send</button>
     </div>
     <div className='block-two-w-button'>
     <button onClick={handleOpenBoostModal} className='button'>Boost</button>
     </div>
     <div className='block-wallet-list'>
     <img src={img} alt="Description" className="float-left image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1'>@username</div>
        <div className='text-home-2'>Bonus</div>
      </div>
      <img src={imgSolanaGold} alt="Description" className="m-10 float-left image-mis" />
      <p className='text-home-2 text-success'>0.0002 <FontAwesomeIcon icon={faCircleCheck} className="fa-icon text-success" /></p>
     </div>
     <div className='block-wallet-list'>
     <img src={img} alt="Description" className="float-left image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1'>@username</div>
        <div className='text-home-2'>Send</div>
      </div>
      <img src={imgSolanaGold} alt="Description" className="m-10 float-left image-mis" />
      <p className='text-home-2 text-danger'>0.0002 <FontAwesomeIcon icon={faCircleXmark} className="fa-icon text-danger" /></p>
     </div>
     <div className='block-wallet-list'>
     <img src={img} alt="Description" className="float-left image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1'>@username</div>
        <div className='text-home-2'>Send</div>
      </div>
      <img src={imgSolanaGold} alt="Description" className="m-10 float-left image-mis" />
      <p className='text-home-2 text-white'>0.0002 <FontAwesomeIcon icon={faSpinner} className="fa-icon text-white" /></p>
     </div>
     <div className='block-wallet-list'>
     <img src={img} alt="Description" className="float-left image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1'>@username</div>
        <div className='text-home-2'>Bonus</div>
      </div>
      <img src={imgSolanaGold} alt="Description" className="m-10 float-left image-mis" />
      <p className='text-home-2 text-success'>0.0002</p>
     </div>
     <div className='block-wallet-list'>
     <img src={img} alt="Description" className="float-left image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1'>@username</div>
        <div className='text-home-2'>Bonus</div>
      </div>
      <img src={imgSolanaGold} alt="Description" className="m-10 float-left image-mis" />
      <p className='text-home-2 text-success'>0.0002</p>
     </div>
     <Modal show={showSendModal} handleClose={handleCloseSendModal}>
      <div className='block-image'>
      <img src={imgWithdrawing} className='boost-image' alt="Placeholder" />
      </div>
        <h2>Enter your personal SOL address</h2>
        <p>This amount will be sent to the SOLANA compatible wallet address</p>
        <p>Enter your personal SOL address</p>
        <input type='text' placeholder='Your SOL (SOLANA) Address'></input>
        <input type='number' placeholder='Amount'></input>
        <button className='modal-button'>Send</button>
      </Modal>
     <Modal show={showBoostModal} handleClose={handleCloseBoostModal}>
     <div className='block-image'>
      <img src={imgBoostModal} className='boost-image' alt="Placeholder" />
      </div>
        <h2>Mining Power</h2>
        <p>Here you can rent mining power for 30 days.</p>
        <p>The investment profitability is 5.18% per day and 155% for 30
				days</p>
        <div>
          <div className='boost-text-top'>
          ⚡ 10.0 GH/s
          </div>

          <div className='boost-text'>
          Total Profit: ~1.55 SOL
          </div>
          <div className='boost-text'>
          Daily Profit: ~0.16 SOL
          </div>
        </div>
        <input type='number' placeholder='Amount' min={1.55} max={100}></input>
        <div className='boost-text'>Minimum amount: 0.003 SOL</div>
        <button className='modal-button'>Add</button>
      </Modal>
    </div>
);
};

export default Wallet;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faTasks, faGift, faWallet, faHome } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import './BottomMenu.css';

const BottomMenu = () => {
  return (
    <div className="bottom-menu">
      <NavLink to="/home" className="menu-item">
        <FontAwesomeIcon icon={faHome} className="fa-icon" />
        <span>Home</span>
      </NavLink>
      <NavLink to="/wallet" className="menu-item">
        <FontAwesomeIcon icon={faWallet} className="fa-icon" />
        <span>Wallet</span>
      </NavLink>
      <NavLink to="/friends" className="menu-item">
        <FontAwesomeIcon icon={faUsers} className="fa-icon" />
        <span>Friends</span>
      </NavLink>
      <NavLink to="/Missions" className="menu-item">
        <FontAwesomeIcon icon={faTasks} className="fa-icon" />
        <span>Missions</span>
      </NavLink>
    </div>
  );
};

export default BottomMenu;

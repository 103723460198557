import React, { useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import img from './solana.png';
import presentimg from './gift-box.png';
import imgSolanaGold from './solana_gold.png';
const Friends = () => {
  const textInputRef = useRef(null);

  const copyText = async () => {
    try {
      const text = textInputRef.current.value;
      await navigator.clipboard.writeText(text);

      toast.success("Link copied", {
        position: "top-center",
        className: 'android-toast',
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
    } catch (error) {
      console.error("Failed: ", error);
    }
  };
  return (
    <div className='container'>
      <ToastContainer />
      <div className='block-text-center'>
      <h2>Invite your friends!</h2>
      </div>
      <div className='block-text-center'>
      <p>And you will receive bonuses</p>
      </div>

      <div className='block-invite'>
     <img src={presentimg} alt="Description" className="invert-image float-left image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1'>Invite a friend</div>
        <div className='mission-img'>
        <img src={imgSolanaGold} alt="Description" className="m-10 float-left image-mis" />
        <div className='text-home-2 float-left'>+0.0001 <span className='text-bold'>SOL</span></div>
        </div>
      </div>
      
     </div>

     <div className='block-f'>
      <input className='gray-input' value="https://test.app" ref={textInputRef} disabled></input>
      <button className='button' onClick={copyText}>Copy</button>
      
     </div>
     <div className='block-text-list-friends'>
     <div className='text-friends'>List of your friends </div>
     </div>
     <div className='block-wallet-list'>
     <img src={img} alt="Description" className="float-left image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1'>@username</div>
        <div className='text-home-2'>Missions: 0 of 4</div>
      </div>
      <p className='text-home-2 text-success'>+0.0001 SOL</p>
     </div>
     <div className='block-wallet-list'>
     <img src={img} alt="Description" className="float-left image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1'>@username</div>
        <div className='text-home-2'>Missions: 0 of 4</div>
      </div>
      <p className='text-home-2 text-success'>+0.0001 SOL</p>
     </div>
     <div className='block-wallet-list'>
     <img src={img} alt="Description" className="float-left image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1'>@username</div>
        <div className='text-home-2'>Missions: 2 of 4</div>
      </div>
      <p className='text-home-2 text-success'>+0.0001 SOL</p>
     </div>
     <div className='block-wallet-list'>
     <img src={img} alt="Description" className="float-left image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1'>@username</div>
        <div className='text-home-2'>Missions: 3 of 4</div>
      </div>
      <p className='text-home-2 text-success'>+0.0002 SOL</p>
     </div>
     <div className='block-wallet-list'>
     <img src={img} alt="Description" className="float-left image" />
      <div className='float-left block-send-position'>
        <div className='text-home-1'>@username</div>
        <div className='text-home-2'>Missions: 4 of 4</div>
      </div>
      <p className='text-home-2 text-success'>+0.0003 SOL</p>
     </div>
    </div>
  );
};

export default Friends;